import React from "react"
import SEO from "../components/reusables/seo"
import Layout from "../components/reusables/Layout"
import isBrowser from "../helpers/isBrowser"
import { graphql } from "gatsby"

const NotFoundPage = ({ data }) => {
  return isBrowser() ? (
    <>
      <SEO title={data.contentful404Page.title} />
      <Layout>
        <h1 className="text-4xl text-center my-5">
          {data.contentful404Page.message}
        </h1>
      </Layout>
    </>
  ) : (
    ""
  )
}

export default NotFoundPage

export const query = graphql`
  query($slug: String!) {
    contentful404Page(slug: { eq: $slug }) {
      title
      message
    }
  }
`
